<template>
  <div class="top-page">
    <div class="top-page__content">
      <router-link class="top-page__logo-container" to="/">
        <!-- Logos -->
        <img
          v-if="currentConf"
          class="top-page__conf-logo mr-6"
          style="width: 100px; height: 100px;"
          :src="`/images/sber-landing-logo.svg`"
        />
        <logo-conf v-if="currentConf" class="top-page__conf-logo" />
      </router-link>

      <!-- <button @click="$router.go(-1)" class="top-page__back"> Назад </button> -->
      <h1 class="mb-4">Турнирная таблица</h1>
      <!-- <div class="top-page__tab-container" v-if="confs.length > 0">
        <button
          v-for="conf in confs"
          :key="conf.id"
          :class="{ active: currentConf === conf.id }"
          class="top-page__tab-item"
          @click="changeTab(conf.id)"
        >
          {{ conf.name }}
        </button>
      </div> -->
      <div class="top-page__main">
        <div class="top-page__table-container">
          <div v-if="false" class="top-page__date-tab-container">
            <button
              v-for="(date, dateIndex) in dates"
              :key="date"
              class="top-page__date-tab"
              :class="{
                activeTab: currentDate === date,
                active: currentConf === 'codefest' ? isActive(date) : false,
                complete: dateIndex === 3 ? isComplete(date) : true,
              }"
              @click="handleChangeDate(date)"
            >
              {{ date }}
            </button>
          </div>
          <div class="top-page__table-status" v-if="isComplete()">
            <span>Турнир завершен, списки лидеров больше не обновляются</span>
          </div>
          <top-table
            :currentUsers="currentUsers"
            :currentUser="currentUser"
            :currentDate="currentDate"
          />
        </div>
        <div class="top-page__prizes-container">
          <template v-if="timer">
            <div class="top-page__timer">
              <digital-timer :interval="timer" with-hours />
            </div>
            <p style="margin-top: 3px">
              Осталось времени до закрытия турнирной таблицы
            </p>
          </template>
          <!-- <div class="top-page__prize">
            <div class="top-page__prize-title">Бонусное задание</div>
            <div class="top-page__prize-subtitle">
              Смарт-дисплей <a href="" target="_blank">СберПортал</a>
            </div>
          </div> -->
          <div class="top-page__prize">
            <div class="top-page__prize-title">{{ places }}</div>
            <div class="top-page__prize-subtitle">
              Награждаются призами
              <!-- <a href="https://www.sber.ru/sberprime" target="_blank"
                >СберПрайм</a
              > -->
            </div>
          </div>
          <p style="margin-bottom: 16px">
            Призы разыгрываются только в дни конференции среди всех игроков.
            Попасть в список победителей можно только один раз за конференцию.
            Читайте
            <a
              href="https://www.notion.so/SberQuiz-04a047e35b6b46158fee8385aa246e97"
              target="_blank"
              >правила полностью</a
            >
          </p>
          <!-- <p>
            Попавшие в список победителей в предыдущие дни могут играть, но
            больше не могут участвовать в турнире и не отображаются в топе. Если
            вы не получили приз, напишите на help@geecko.com
          </p> -->
        </div>
      </div>
    </div>
    <!-- <div class="top-page__footer">
      <div class="top-page__footer-main">
        <footer-content />
      </div>
    </div> -->
  </div>
</template>

<script>
// import FooterContent from "@/components/UI/FooterContent";
import DigitalTimer from '@/components/UI/DigitalTimer';
import TopTable from '@/components/UI/TopTable';
import LogoConf from '../components/UI/LogoConf.vue';
import { CONF_SETTINGS } from '../confSettings';

export default {
  name: 'TopPage',
  components: {
    // FooterContent,
    DigitalTimer,
    TopTable,
    LogoConf,
  },
  data() {
    return {
      confs: [],
      currentConf: null,
      users: [],
      currentUser: null,
      timer: null,
      dates: [],
      currentDate: null,
      winners: {},
      currentUsers: [],
      places: CONF_SETTINGS.awarding.positions,
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
  },
  watch: {
    // '$route.query.code': {
    //   immediate: true,
    //   handler (to) {
    //     if (to) {
    //       this.$log('$route.query.code TO', to)
    //       this.$router.replace('/top')
    //     }
    //   }
    // },
    currentDate(val) {
      this.$log('currentDate TO', val);
      if (this.currentConf === 'codefest') {
        if (
          this.winners.winners['2021-05-29 09:59:00'] &&
          this.currentDate === '29 мая 9:00 - 13:59'
        )
          return (this.currentUsers = this.winners.winners[
            '2021-05-29 09:59:00'
          ]);
        if (
          this.winners.winners['2021-05-29 14:00:00'] &&
          this.currentDate === '29 мая 14:00 - 18:00'
        )
          return (this.currentUsers = this.winners.winners[
            '2021-05-29 14:00:00'
          ]);
        if (
          this.winners.winners['2021-05-30 09:59:00'] &&
          this.currentDate === '30 мая 9:00 - 13:59'
        )
          return (this.currentUsers = this.winners.winners[
            '2021-05-30 09:59:00'
          ]);
        if (
          this.winners.winners['2021-05-30 14:00:00'] &&
          this.currentDate === '30 мая 14:00 - 18:00'
        )
          return (this.currentUsers = this.winners.winners[
            '2021-05-30 14:00:00'
          ]);

        this.currentUsers = this.winners.users;
      }
      if (this.currentConf === 'codefest') {
        this.currentUser = null;
      } else {
        if (this.winners[val]) {
          this.currentUsers = this.winners[val];
        } else if (
          this.dates.includes(val) &&
          val ===
            new Date().toLocaleString('ru', { month: 'long', day: 'numeric' })
        ) {
          this.currentUsers = this.users;
        } else {
          this.currentUsers = [];
        }
      }
      console.log('CurrentUsers', this.currentUsers);
    },
    currentConf(id) {
      console.log(this.currentUsers);
      this.$log('currentConf TO', id);
      this.currentDate = null;
      // this.$nextTick(() => {})
      this.$http.get(`/conf/${id}/top`).then((resp) => {
        this.users = resp.data.users;
        this.currentUser = resp.data.current;
        if (this.currentUser) {
          this.currentUser.date = new Date(
            resp.data.current.date
          ).toLocaleString('ru', { month: 'long', day: 'numeric' });
        }
        this.timer = resp.data.timer;
        this.dates = resp.data.conf.dates.map((d) =>
          new Date(d).toLocaleString('ru', { month: 'long', day: 'numeric' })
        );
        console.log('resp', resp.data);
        if (id === 'dump') {
          this.dates = [
            '14 мая 9:00 - 14:45',
            '14 мая 15:00 - 20:00',
            '15 мая 9:00 - 20:00',
          ];
          this.winners = resp.data;
          console.log('winners', this.winners);
        }
        if (id === 'codefest') {
          this.dates = [
            '29 мая 9:00 - 13:59',
            '29 мая 14:00 - 18:00',
            '30 мая 9:00 - 13:59',
            '30 мая 14:00 - 18:00',
          ];
          this.winners = resp.data;
        } else {
          this.winners = Object.fromEntries(
            Object.entries(resp.data.winners).map((x) => [
              new Date(x[0].replace(/-/g, '/')).toLocaleString('ru', {
                month: 'long',
                day: 'numeric',
              }),
              x[1],
            ])
          );
        }

        // new Date().toLocaleString("ru", { hour: "numeric", minute: "2-digit"}) > 12
        // this.winners = resp
        if (
          this.dates.includes(
            new Date().toLocaleString('ru', { month: 'long', day: 'numeric' })
          )
        ) {
          this.currentDate = new Date().toLocaleString('ru', {
            month: 'long',
            day: 'numeric',
          });
        } else {
          this.currentDate = this.dates[3];
        }
      });
    },
  },
  mounted() {},
  created() {
    this.$http
      .get('/confs')
      .then((resp) => {
        this.confs = resp.data;
      })
      .then(() => {
        this.currentConf = CONF_SETTINGS.id;
      });
  },
  methods: {
    changeTab(id) {
      this.currentConf = id;
    },
    handleChangeDate(date) {
      this.currentDate = date;
    },
    isActive(date) {
      if (date)
        return (
          date.slice(0, 2) ===
          new Date().toLocaleString('ru', { day: 'numeric' })
        );
    },
    isComplete(date) {
      if (
        date === '29 мая 9:00 - 13:59' ||
        date === '29 мая 14:0 - 18:00' ||
        date === '30 мая 9:00 - 13:59' ||
        date === '30 мая 14:00 - 18:00'
      )
        return false;
      if (date)
        return (
          date.slice(0, 1) > new Date().toLocaleString('ru', { day: 'numeric' })
        );
    },
  },
};
</script>
<style lang="scss" scoped>
.top-page {
  position: relative;
  // transform: rotate(90deg);
  h1 {
    font-family: 'BlenderPro Bold';
    font-weight: bold;
    font-size: 56px;
    line-height: 100%;
    text-transform: uppercase;
    color: #ffffff;
  }
  min-height: 100vh;
  box-sizing: border-box;
  background: linear-gradient(
      35.66deg,
      #090812 37.95%,
      rgba(29, 29, 30, 0.19) 98.88%
    ),
    #090812;
  &__content {
    max-width: 1280px;
    margin: 0 auto;
    padding: 114px 72px 0px 72px;
    position: relative;
  }
  &__footer {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, #19192f, #19192f),
      linear-gradient(0deg, #0c0c0c, #0c0c0c),
      linear-gradient(0deg, #f4f4f4, #f4f4f4);
    &-main {
      max-width: 1280px;
      margin: 0 auto;
      --indent: 72px;
      padding-left: var(--indent);
      padding-right: var(--indent);
    }
  }
  &__logo-container {
    position: absolute;
    left: 72px;
    top: 32px;
  }
  &__conf-logo {
    display: inline-block;
    vertical-align: middle;
    margin-top: -10px;
    width: 50px;
  }
  &__sber-logo {
    background: url('/images/sber-logo.svg');
    width: 104px;
    height: 29px;
    margin-right: 40px;
    display: inline-block;
    vertical-align: top;
  }
  &__back {
    font-family: 'BlenderPro Bold';
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #7f7e8a;
    padding-left: 14px;
    margin-bottom: 18px;
    position: relative;
    &:after {
      transition: 0.25s;
      content: '';
      position: absolute;
      width: 10px;
      --height: 9px;
      height: var(--height);
      left: 0;
      top: calc(50% - var(--height) / 2);
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z' fill='%237F7E8A'/%3E%3C/svg%3E%0A");
    }
    &:hover {
      color: var(--primary);
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z' fill='%23FFEF40'/%3E%3C/svg%3E%0A");
      }
    }
  }
  &__tab {
    &-container {
      margin-top: 32px;
      width: max-content;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      margin-bottom: 56px;
    }
    &-item {
      font-family: 'BlenderPro Bold';
      border: none;
      background: transparent;
      font-size: 24px;
      line-height: 100%;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
      margin: 0 16px;
      display: inline-block;
      padding-bottom: 9px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active {
        color: #ffffff;
        border-bottom: 1px solid #6dedfc;
      }
      &:focus {
        outline: none;
      }
    }
  }
  &__table-container {
    text-align-last: initial;
    vertical-align: top;
    width: 58%;
    display: inline-block;
  }
  &__prizes-container {
    text-align-last: initial;
    width: 23%;
    min-width: 297px;
    display: inline-block;
    vertical-align: top;
    & p {
      font-size: 16px;
      line-height: 120%;
      color: #aeadb1;
      & a {
        color: #ffffff;
        font-size: 16px;
        line-height: 120%;
      }
    }
  }
  &__prize {
    border: 2px solid #aeadb1;
    padding: 24px 10px 24px 31px;
    --clip-size: 20px;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - var(--clip-size)),
      calc(100% - var(--clip-size)) 100%,
      0 100%
    );
    position: relative;
    margin-top: 26px;
    &:last-of-type {
      margin-top: 32px;
      margin-bottom: 24px;
    }
    &:before {
      position: absolute;
      content: '';
      border-bottom: var(--clip-size) solid #aeadb1;
      border-left: var(--clip-size) solid transparent;
      bottom: 0;
      right: 0;
    }
    &-title {
      font-family: 'BlenderPro Bold';
      font-size: 32px;
      line-height: 120%;
      color: #ffffff;
    }
    &-subtitle {
      padding-top: 8px;
      font-size: 18px;
      line-height: 24px;
      font-family: 'BlenderPro';
      color: rgba(255, 255, 255, 0.9);
      & a {
        //text-decoration: underline;
        color: rgba(255, 255, 255);
        position: relative;
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.9);
          transition: 0.25s;
        }
        &:hover {
          color: var(--secondary);
          &:after {
            background: var(--secondary);
          }
        }
      }
    }
  }
  &__main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__timer {
    font-family: 'BlenderPro Bold';
    font-size: 32px;
    line-height: 120%;
    color: #ffffff;
  }
  &__date-tab {
    font-family: 'BlenderPro Bold';
    font-size: 18px;
    line-height: 100%;
    height: 46px;
    color: rgba(255, 255, 255, 0.5);
    padding: 22px 13px 0 13px;
    border: none;
    background: transparent;
    display: inline-block;
    margin: 0 8px;
    position: relative;

    &.activeTab {
      background: rgba(85, 81, 114, 0.5);
      border-radius: 4px;
      color: #ffffff;
    }
    &:after {
      position: absolute;
      top: 6px;
      left: 13px;
      font-size: 12px;
      line-height: 100%;
      color: rgba(255, 255, 255, 0.6);
      content: 'Завершено';
    }
    &.active {
      &:after {
        content: 'Сейчас идет';
        color: #ffef40;
      }
    }
    &.complete {
      &:after {
        content: 'Завершено';
        color: rgba(255, 255, 255, 0.6);
      }
    }
    &:focus {
      outline: none;
    }
    &-container {
      margin-bottom: 24px;
      margin-left: -8px;
      margin-right: -8px;
    }
  }
  &__table-status {
    min-height: 73px;
    line-height: 100%;
    opacity: 0.8;
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-family: 'BlenderPro Bold';
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 16px;
    padding: 28px 32px 28px 32px;
    & span {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 12px);
        left: -32px;
        width: 16px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z' fill='white' fill-opacity='0.5'/%3E%3C/svg%3E%0A");
      }
    }
  }
}

@media (max-width: 992px) {
  .top-page {
    &__table-container {
      width: 108%;
    }
    &__prizes-container {
      width: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .top-page {
    &__prize {
      display: inline-block;
      width: 48%;
      &:first-of-type {
        margin-right: 4%;
      }
    }
  }
}

@media (max-width: 768px) {
  .top-page {
    h1 {
      font-size: 24px;
      line-height: 28px;
    }
    &__logo-container {
      left: 8px;
      top: 16px;
    }
    &__content {
      padding: 86px 8px 24px 8px;
    }
    &__tab {
      &-container {
        margin-top: 24px;
        margin-bottom: 24px;
        overflow: auto;
        max-width: 100%;
        white-space: nowrap;
      }
      &-item {
        font-size: 16px;
      }
    }
    &__date-tab {
      padding: 22px 8px 8px 8px;
      &-container {
        margin-bottom: 9px;
        overflow: auto;
        max-width: 100%;
        white-space: nowrap;
      }
    }
    &__table-container {
      margin-bottom: 24px;
    }
    &__prize {
      width: 100%;
    }
    &__footer {
      width: 100%;
      position: initial;
      &-main {
        --indent: 8px;
      }
    }
  }
}
</style>
